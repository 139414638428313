<template>
	<div>
		<div class="headline"><v-icon>email</v-icon> Where can we reach you?</div>

		<v-text-field
			v-model="details.firstName"
			label="First name"
			:error-messages="firstNameErrors"
			required
			@keyup.enter.native="nextStep"
			type="text"/>

		<v-text-field
			v-model="details.lastName"
			label="Last name"
			:error-messages="lastNameErrors"
			required
			@keyup.enter.native="nextStep"
			type="text"/>

		<v-text-field
			v-model="details.email"
			label="Email"
			:error-messages="emailErrors"
			required
			@keyup.enter.native="nextStep"
			type="email"/>

		<v-btn
			text
			@click="prevStep">Back</v-btn>
		<v-btn
			color="primary"
			@click="nextStep">Next</v-btn>
	</div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
	name: 'Start',
	props: {
		merchant: null,
		order: null,
		project: null,
		details: null
	},
	data: () => {
		return {};
	},
	validations: {
		details: {
			email: { required, email },
			firstName: { required },
			lastName: { required }
		}
	},
	computed: {
		emailErrors() {
			const errors = [];
			if(this.$v.details.email.$dirty) {
				this.$v.details.email.required || errors.push('Email is required');
				this.$v.details.email.email || errors.push('Email is not valid');
			}

			return errors;
		},
		firstNameErrors() {
			const errors = [];
			if(this.$v.details.firstName.$dirty) {
				this.$v.details.firstName.required || errors.push('First name is required');
			}

			return errors;
		},
		lastNameErrors() {
			const errors = [];
			if(this.$v.details.lastName.$dirty) {
				this.$v.details.lastName.required || errors.push('Last name is required');
			}

			return errors;
		}
	},
	methods: {
		prevStep() {
			this.$emit('prev-step');
		},
		nextStep() {
			this.$v.$touch();

			let error = this.$v.$error;
			if(!error) {
				this.$emit('next-step');
			}
		}
	}
};
</script>

<style scoped>
	.v-list {
		text-align: left;
	}

	.v-btn {
		margin: 0 0.5em;
	}
</style>