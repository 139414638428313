<template>
	<div>
		<div v-if="!loading && this.order">
			<v-list two-line>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title><b>Book:</b> &nbsp;{{ details.projectName }}</v-list-item-title>
						<v-list-item-subtitle>{{ details.copies }} copies of a {{ details.pages }} page {{ details.bookSize }} {{ details.coverType.toLowerCase() }} book</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>

				<v-list-item>
					<v-list-item-content>
						<v-list-item-title><b>Account:</b> &nbsp;{{ details.firstName }} {{ details.lastName }}</v-list-item-title>
						<v-list-item-subtitle>Email: &nbsp;{{ details.email }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>

				<v-divider class="order-summary-divider" v-if="merchant.upfrontFee"></v-divider>

				<v-list-item v-if="merchant.upfrontFee">
					<v-list-item-content>
						<v-list-item-title><b>Down Payment:</b> {{ merchant.upfrontFee | money }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-alert
				type="error"
				:value="!!errorMessage"
				transition="scale-transition">

				{{ errorMessage }}
			</v-alert>

			<v-btn
				text
				@click="prevStep">Back</v-btn>
			<v-btn
				color="primary"
				@click="nextStep">Get Started</v-btn>
		</div>

		<v-progress-circular indeterminate v-if="loading"></v-progress-circular>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'Summary',
	props: {
		details: null,
		lastStepEvent: null,
		merchant: null,
		order: null
	},
	data() {
		return {
			loading: false,
			errorMessage: null
		};
	},
	methods: {
		nextStep() {
			this.loading = true;
			this.errorMessage = null;

			this.lastStepEvent().then((data) => {
				this.details.chargeToken = data.chargeToken;

				return axios.post(this.baseUrl + 'api/v1/register', this.details);
			}).then((response) => {
				let order = response.data.order;
				this.loading = false;
				this.$router.push('/landing/receipts/' + order.orderId);
			}).catch((error) => {
				// eslint-disable-next-line
				console.error(error);

				if(error && error.message) {
					this.errorMessage = error.message;
				}

				this.loading = false;
			});
		},
		prevStep() {
			this.errorMessage = null;
			this.$emit('prev-step');
		}
	}
};
</script>

<style scoped>
	.v-list {
		text-align: left;
	}

	.v-btn {
		margin: 0 0.5em;
	}
</style>