<template>
	<div>
		<v-progress-circular indeterminate v-if="loading"></v-progress-circular>
		<div v-else>
			<v-list two-line>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title><b>Name:</b> {{ details.firstName }} {{ details.lastName }}</v-list-item-title>
						<v-list-item-subtitle>Email: {{ details.email }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>

				<v-list-item>
					<v-list-item-content>
						<v-list-item-title><b>Book:</b> &nbsp;{{ project.jobName }}</v-list-item-title>
						<v-list-item-subtitle>{{ details.quantity }} copies of a {{ project.pages }} page book</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>

				<v-divider class="order-summary-divider"></v-divider>

				<v-list-item>
					<v-list-item-content>
						<v-list-item-title><b>Sub Total:</b> {{ subTotal | money }}</v-list-item-title>
						<v-list-item-subtitle><b>Shipping:</b> {{ prices.flatRateShippingFee | moneyCurrency }}</v-list-item-subtitle>
						<v-list-item-title>Order Total: {{ orderTotal | money }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-alert
				type="error"
				:value="!!errorMessage"
				transition="scale-transition">

				{{ errorMessage }}
			</v-alert>

			<v-btn
				text
				@click="prevStep">Back</v-btn>
			<v-btn
				color="primary"
				@click="nextStep">Place Order</v-btn>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'Summary',
	props: ['lastStepEvent', 'project', 'details', 'prices'],
	data() {
		return {
			loading: false,
			errorMessage: null
		};
	},
	computed: {
		subTotal() {
			return this.prices.perBookPrice * this.details.quantity;
		},
		orderTotal() {
			return this.subTotal + this.prices.flatRateShippingFee;
		}
	},
	methods: {
		nextStep() {
			this.loading = true;
			this.errorMessage = null;

			this.lastStepEvent().then((data) => {
				return axios.post(this.baseUrl + 'api/v1/store', {
					...this.details,
					projectId: this.$route.params.id,
					chargeToken: data.chargeToken,
					expectedOrderTotal: this.orderTotal
				});
			}).then((response) => {
				let order = response.data.order;
				this.loading = false;
				this.$router.push('/landing/receipts/' + order.orderId);
			}).catch((error) => {
				// eslint-disable-next-line
				console.error(error);

				if(error?.response?.data) {
					this.errorMessage = error.response.data.reason;
				} else if(error?.message) {
					this.errorMessage = error.message;
				}

				this.loading = false;
			});
		},
		prevStep() {
			this.errorMessage = null;
			this.$emit('prev-step');
		}
	}
};
</script>

<style scoped>
	.v-list {
		text-align: left;
	}

	.v-btn {
		margin: 0 0.5em;
	}
</style>