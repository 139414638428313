<template>
	<v-layout justify-center>
		<v-flex sm12 md8>
			<stepper/>
		</v-flex>
	</v-layout>
</template>

<script>
import Stepper from './components/Stepper';

export default {
	name: 'StorePage',
	components: {
		Stepper
	}
}
</script>

<style scoped>

</style>