<template>
	<div class="landing-page white--text">
		<img class="app-logo" :src="largeLogoUrl">
		<h1>{{ appName }}</h1>
		
		<template v-if="studioOrg">
			<div class="subheading" v-html="landingHtml" />

			<v-btn color="secondary" :to="registerLink" class="register-button">
				Register
			</v-btn>
		</template>

		<template v-else>
			<div class="subheading">Join us in our quest for world domination by logging in below!</div>

			<v-btn color="secondary" to="/landing/login" class="register-button">
				Login
			</v-btn>
		</template>
	</div>
</template>

<script>
import bbCodeParser from 'js-bbcode-parser';

export default {
	name: 'RegisterPanel',
	props: {
		appName: null,
		studioOrg: null
	},
	computed: {
		landingHtml() {
			let text = this.studioOrg?.landingPageText || 'Join us in our quest for world domination by registering below!';
			text = text.replace(/(?:\r\n|\r\r|\r|\n)/g, '<br/>');

			return bbCodeParser.parse(text);
		},
		registerLink() {
			return '/landing/register?' + this.customerQueryAppend;
		}
	}
}
</script>

<style scoped>
	.landing-page {
		width: 100%;
		z-index: 1;
	}

	@media all and (orientation:portrait) {
		.app-logo {
			max-width: 90%;
			max-height: 20vh;
		}
	}

	@media all and (orientation:landscape) {
		.app-logo {
			max-width: 40%;
			max-height: 30vh;
			margin-top: 4em;
		}

		.landing-page {
			align-self: flex-start;
		}
	}

	.register-button {
		margin-top: 2em;
	}
</style>