<template>
	<div>
		<div v-if="!loading">
			<div class="headline"><v-icon>forum</v-icon> What do you want to call your Book?</div>

			<v-text-field
				v-model="details.projectName"
				label="Book name"
				:error-messages="projectNameErrors"
				required
				@keyup.enter.native="nextStep"
				type="text"/>

			<div class="headline"><v-icon>book</v-icon> What type of Book are you looking to create?</div>

			<v-radio-group v-model="selectedCategory" v-if="bookFormatCategories.length > 1" row>
				<v-radio
					v-for="category in bookFormatCategories"
					:key="category"
					:label="category"
					:value="category"
				></v-radio>
			</v-radio-group>

			<v-radio-group v-model="details.bookFormatId" :error-messages="bookFormatIdErrors" row>
				<v-radio
					v-for="bookFormat in displayedBookFormats"
					:key="bookFormat.id"
					:label="bookFormat.name"
					:value="bookFormat.id"
				></v-radio>
			</v-radio-group>

			<v-btn
				text
				@click="prevStep"
				v-if="currentStep > 1">Back</v-btn>
			<v-btn
				color="primary"
				@click="nextStep">Continue</v-btn>

			<v-alert
				type="error"
				:value="!!errorMessage"
				transition="scale-transition"
				style="margin-top: 0.5em">

				{{ errorMessage }}
			</v-alert>
		</div>

		<v-progress-circular indeterminate v-if="loading"></v-progress-circular>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
	props: {
		currentStep: null,
		details: null,
		bookFormats: null,
		order: null
	},
	name: 'ProjectInformation',
	data() {
		return {
			selectedCategory: null,
			loading: false,
			errorMessage: null
		};
	},
	validations() {
		return {
			details: {
				bookFormatId: { required },
				projectName: { required }
			}
		};
	},
	computed: {
		bookFormatCategories() {
			let categories = [];
			this.bookFormats.forEach((bookFormat) => {
				let category = bookFormat.categories[0] || 'Other';
				if(categories.indexOf(category) === -1) {
					categories.push(category);
				}
			});

			return categories;
		},
		requireMultiplesOfFour() {
			if(this.details.bookFormatId) {
				let bookFormat = this.bookFormats.find((bookFormat) => bookFormat.id === this.details.bookFormatId);
				if(bookFormat && bookFormat.requireMultiplesOfFour) {
					return true;
				}
			}

			return false;
		},
		displayedBookFormats() {
			return this.bookFormats.filter((bookFormat) => {
				return (bookFormat.categories[0] || 'Other') === this.selectedCategory;
			});
		},

		bookFormatIdErrors() {
			const errors = [];
			if(this.$v.details.bookFormatId.$dirty) {
				this.$v.details.bookFormatId.required || errors.push('Book size is required');
			}

			return errors;
		},
		projectNameErrors() {
			const errors = [];
			if(this.$v.details.projectName.$dirty) {
				this.$v.details.projectName.required || errors.push('Project name is required');
			}

			return errors;
		}
	},
	watch: {
		selectedCategory() {
			this.details.bookFormatId = null;
		}
	},
	methods: {
		nextStep() {
			this.$v.$touch();

			let error = this.$v.$error;
			if(!error) {
				this.$emit('next-step');
			}
		},
		prevStep() {
			this.$emit('prev-step');
		}
	},
	mounted() {
		this.selectedCategory = this.bookFormatCategories[0];
	}
};
</script>

<style scoped>
	
</style>