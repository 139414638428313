<template>
	<div>
		<div v-if="!loading">
			<div class="headline"><v-icon>forum</v-icon> What cover and fonts do you want for your Book?</div>
			<div class="caption">This can be changed later while designing the Book</div>

			<v-radio-group v-model="selectedTheme" row>
				<v-radio
					v-for="theme in computedThemes"
					:key="theme.id"
					:label="theme.title"
					:value="theme.id"
					class="theme-radio-item"
				>

				<template slot="label">
					<v-img :src="theme.Preview.cdnUrl" v-if="theme.Preview" :contain="true" style="break-after: always" />
					<div class="theme-radio-label">{{ theme.title }}</div>
				</template>
				</v-radio>
			</v-radio-group>

			<v-btn
				text
				@click="prevStep"
				v-if="currentStep > 1">Back</v-btn>
			<v-btn
				color="primary"
				@click="nextStep">Continue</v-btn>

			<v-alert
				type="error"
				:value="!!errorMessage"
				transition="scale-transition"
				style="margin-top: 0.5em">

				{{ errorMessage }}
			</v-alert>
		</div>

		<v-progress-circular indeterminate v-if="loading"></v-progress-circular>
	</div>
</template>

<script>
export default {
	props: {
		currentStep: null,
		details: null,
		themes: null,
		order: null
	},
	name: 'ThemeSelector',
	data() {
		return {
			selectedTheme: this.details.theme ? this.details.theme.id : 'none',
			loading: false,
			errorMessage: null
		};
	},
	computed: {
		computedThemes() {
			let themes = this.themes.slice(0);
			themes.push({
				id: 'none',
				title: 'I will pick a theme later'
			});

			return themes;
		}
	},
	watch: {
		selectedTheme(value) {
			if(value == 'none') {
				this.details.theme = null;
			} else {
				this.details.theme = this.themes.find((theme) => {
					return theme.id == value;
				});
			}
		}
	},
	methods: {
		nextStep() {
			this.$emit('next-step');
		},
		prevStep() {
			this.$emit('prev-step');
		}
	}
};
</script>

<style scoped>
	.theme-radio-item {
		max-width: 15em;
	}
	.theme-radio-item >>> .v-label {
		flex-wrap: wrap;
	}

	.theme-radio-label {
		text-align: center;
		width: 100%;
	}
</style>