<template>
	<v-layout justify-center>
		<v-flex sm12 md8>
			<stepper @loadRegistration="loadRegistration"/>
		</v-flex>
	</v-layout>
</template>

<script>
import Stepper from './components/Stepper';

export default {
	name: 'RegisterPage',
	data() {
		return {
			studioOrg: null
		};
	},
	computed: {
		studioOrgDisplay() {
			if(this.studioOrg) {
				return this.studioOrg.title;
			} else {
				return 'PLIC Memories';
			}
		}
	},
	methods: {
		loadRegistration({studioOrg}) {
			this.studioOrg = studioOrg;
		}
	},
	components: {
		Stepper
	}
}
</script>

<style scoped>

</style>