import Vue from 'vue';
import Router from 'vue-router';
import LandingPage from './landing/LandingPage';
import RegisterPage from './register/RegisterPage';
import ReceiptPage from './receipts/ReceiptPage';
import LoginPage from '../PlicCanvas/vue/login/LoginPage';
import OAuthRedirect from './OAuthRedirect';
import StorePage from './store/StorePage';

Vue.use(Router);

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/landing',
			name: 'landing',
			component: LandingPage
		},
		{
			path: '/landing/register',
			name: 'register',
			component: RegisterPage
		},
		{
			path: '/landing/login',
			name: 'login',
			component: LoginPage,
			alias: ['/login']
		},
		{
			path: '/landing/receipts/:id',
			name: 'receipt',
			component: ReceiptPage
		},
		{
			path: '/landing/oauth-redirect',
			name: 'oauth-redirect',
			component: OAuthRedirect
		},
		{
			path: '/landing/store/:id',
			name: 'store',
			component: StorePage
		},
		// NOTE: Everything under /landing/ MUST come before this since it catches everything else
		{
			path: '/landing/:customer',
			name: 'landing',
			component: LandingPage
		}
	],
	customer: null
})
