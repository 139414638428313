import Vue from 'vue';

window.storeCurrency = 'usd';
Vue.filter('money', function(value) {
	return '$' + (parseFloat(value) / 100).toFixed(2);
});
Vue.filter('moneyCurrency', function(value) {
	let moneySymbol = '$';
	if(window.storeCurrency !== 'usd') {
		moneySymbol = window.storeCurrency.toUpperCase() + ' $';
	}

	return moneySymbol + (parseFloat(value) / 100).toFixed(2);
});