<template>
	<div>
		<div class="form-row" :style="formStyle" style="margin-bottom: 1em">
			<div ref="stripe-card"></div>

			<v-alert
				type="success"
				:value="true"
				transition="scale-transition"
				style="margin-top: 2em">

				We charge {{ merchant.upfrontFee | money }} for a down payment to get started.  This charge is refundable anytime in the next two weeks you decide to not build a Book with us.
			</v-alert>

			<v-alert
				type="error"
				:value="!!stripeError"
				transition="scale-transition">

				{{ stripeError }}
			</v-alert>
		</div>

		<v-progress-circular indeterminate v-if="loading" style="width: 100%"></v-progress-circular>

		<v-btn
			text
			@click="prevStep">Back</v-btn>
		<v-btn
			color="primary"
			@click="nextStep">Continue</v-btn>
	</div>
</template>

<script>
export default {
	name: 'StripeForm',
	props: {
		merchant: null
	},
	data: function() {
		return {
			loading: true,
			stripeError: ''
		};
	},
	computed: {
		formStyle: function() {
			if(this.loading) {
				return {
					display: 'none'
				};
			} else {
				return {};
			}
		}
	},
	methods: {
		nextStep: function() {
			this.loading = true;
			this.stripe.createToken(this.card).then((result) => {
				if(result.error) {
					this.loading = false;
					this.stripeError = result.error.message;
				} else {
					this.loading = false;

					this.$emit('next-step', () => {
						return this.getOrderData();
					});
				}
			});
		},
		prevStep: function() {
			this.$emit('prev-step');
		},
		getOrderData: function() {
			return new Promise((resolve, reject) => {
				this.stripe.createToken(this.card).then((result) => {
					if(result.error) {
						reject(result.error);
					} else {
						resolve({
							chargeToken: result.token.id
						});
					}
				}).catch((error) => {
					reject(error);
				});
			});
		}
	},
	mounted: function() {
		this.stripe = window.Stripe(this.merchant.key);
		let elements = this.stripe.elements();

		this.card = elements.create('card');
		this.card.addEventListener('ready', () => {
			this.loading = false;
		});

		this.card.mount(this.$refs['stripe-card']);

		this.card.addEventListener('change', (result) => {
			if(result.error) {
				this.stripeError = result.error.message;
			} else {
				this.stripeError = '';
			}
		});
	}
};
</script>

<style scoped>
	.StripeElement {
		background-color: white;
		height: 40px;
		padding: 10px 12px;
		border-radius: 4px;
		border: 1px solid transparent;
		box-shadow: 0 1px 3px 0 #e6ebf1;
		-webkit-transition: box-shadow 150ms ease;
		transition: box-shadow 150ms ease;
	}

	.StripeElement--focus {
		box-shadow: 0 1px 3px 0 #cfd7df;
	}

	.StripeElement--invalid {
		border-color: #fa755a;
	}

	.StripeElement--webkit-autofill {
		background-color: #fefde5 !important;
	}

	.v-btn {
		margin: 0 0.5em;
	}
</style>