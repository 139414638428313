<template>
	<v-stepper v-model="currentStep" class="elevation-10">
		<v-stepper-header>
			<template v-for="(step, index) in steps">

				<v-stepper-step :key="step.component.name + '-step'"
					:complete="step > index"
					:step="index + 1">{{ step.title }}
					</v-stepper-step>
				<v-divider :key="step.component.name + '-divider'" v-if="index != steps.length - 1"></v-divider>
			</template>
		</v-stepper-header>

		<v-stepper-items>
			<v-stepper-content v-for="(step, index) in steps" :key="step.component.name" :step="index + 1">
				<component
					:is="step.component"
					v-on:next-step="nextStep"
					v-on:prev-step="prevStep"
					v-on:update-order="updateOrder"
					:is-visible="(index + 1) == currentStep"
					:current-step="currentStep"
					:merchant="merchant"
					:last-step-event="lastStepEvent"
					:project="project"
					:details="details"
					:prices="prices"
					v-if="!loading && !errorMessage"/>
			</v-stepper-content>
		</v-stepper-items>

		<v-alert
			type="error"
			:value="!!errorMessage"
			transition="scale-transition">

			{{ errorMessage }}
		</v-alert>

		<v-progress-circular indeterminate v-if="loading" style="margin-bottom: 2em"></v-progress-circular>
	</v-stepper>
</template>

<script>
import Start from './Start';
import ContactInformation from './ContactInformation';
import ShippingInformation from './ShippingInformation';
import StripeForm from './StripeForm';
import Summary from './Summary';
import axios from 'axios';

export default {
	name: 'Stepper',
	data: function() {
		return {
			currentStep: 1,
			merchant: null,
			currency: 'usd',
			loading: true,
			errorMessage: null,
			lastStepEvent: null,
			project: null,
			details: {
				quantity: 1,
				firstName: '',
				lastName: '',
				email: '',
				shippingInformation: {
					addressLine1: null,
					addressLine2: null,
					city: null,
					state: null,
					zip: null,
					loading: false,
					errorMessage: null
				}
			},
			prices: null
		};
	},
	computed: {
		steps() {
			return [
				{
					title: 'Order',
					component: Start
				},
				{
					title: 'Contact Information',
					component: ContactInformation
				},
				{
					title: 'Shipping Information',
					component: ShippingInformation
				},
				{
					title: 'Credit Card',
					component: StripeForm
				},
				{
					title: 'Confirm',
					component: Summary
				}
			];
		}
	},
	methods: {
		nextStep(lastStepEvent) {
			this.currentStep++;

			this.lastStepEvent = lastStepEvent;
		},
		prevStep() {
			this.currentStep--;
		},
		updateOrder(order) {
			this.order = order;
		}
	},
	mounted() {
		this.loading = true;
		this.errorMessage = null;

		axios.get(this.baseUrl + 'api/v1/store?projectId=' + this.$route.params.id).then((response) => {
			this.currency = response.data.currency;
			this.order = response.data.order;
			this.merchant = response.data.merchant;
			this.project = response.data.project;
			this.prices = response.data.prices;
			if(this.prices?.minimumBookQuantity) {
				this.details.quantity = this.prices.minimumBookQuantity;
			}
			this.loading = false;
		}).catch((error) => {
			// eslint-disable-next-line
			console.error(error);

			if(error?.response?.data?.reason) {
				this.errorMessage = 'Failed to load: ' + error.response.data.reason;
			} else if(error?.message) {
				this.errorMessage = error.message;
			}
			this.loading = false;
		});
	}
};
</script>

<style scoped>
	
</style>