<template>
	<v-layout justify-center align-center>
		<register-panel :app-name="appName" :studio-org="studioOrg" />
	</v-layout>
</template>

<script>
import RegisterPanel from './components/RegisterPanel';

export default {
	name: 'LandingPage',
	props: {
		appName: null,
		studioOrg: null
	},
	components: {
		RegisterPanel
	}
}
</script>

<style scoped>
	
</style>