<template>
	<v-stepper v-model="currentStep" class="elevation-10">
		<v-stepper-header>
			<template v-for="(step, index) in steps">

				<v-stepper-step :key="step.component.name + '-step'"
					:complete="step > index"
					:step="index + 1">{{ step.title }}
					</v-stepper-step>
				<v-divider :key="step.component.name + '-divider'" v-if="index != steps.length - 1"></v-divider>
			</template>
		</v-stepper-header>

		<v-stepper-items>
			<v-stepper-content v-for="(step, index) in steps" :key="step.component.name" :step="index + 1">
				<component
					:is="step.component"
					v-on:next-step="nextStep"
					v-on:prev-step="prevStep"
					v-on:update-order="updateOrder"
					:is-visible="(index + 1) == currentStep"
					:current-step="currentStep"
					:details="details"
					:studio-org="studioOrg"
					:merchant="merchant"
					v-if="!loading && displayStep"
					:last-step-event="lastStepEvent"
					:book-formats="bookFormats"
					:themes="themes"
					:order="order"/>
			</v-stepper-content>
		</v-stepper-items>

		<v-alert
			type="error"
			:value="!!errorMessage"
			transition="scale-transition">

			{{ errorMessage }}
		</v-alert>

		<v-progress-circular indeterminate v-if="loading" style="margin-bottom: 2em"></v-progress-circular>
	</v-stepper>
</template>

<script>
import PersonalInformation from './PersonalInformation';
import ProjectInformation from './ProjectInformation';
import ThemeSelector from './ThemeSelector';
import Summary from './Summary';
import StripeForm from './StripeForm';
import axios from 'axios';

export default {
	name: 'Stepper',
	data: function() {
		return {
			currentStep: 1,
			details: {
				studioOrgId: null,
				projectName: '',
				coverType: '',
				bookFormatId: '',
				theme: null,
				pages: 40,
				copies: 1,
				firstName: '',
				lastName: '',
				email: '',
				chargeToken: null,
				orderId: null
			},
			studioOrg: null,
			merchant: null,
			currency: 'usd',
			bookFormats: [],
			loading: true,
			errorMessage: null,
			lastStepEvent: null,
			order: null,
			themes: [],
			displayStep: true
		};
	},
	computed: {
		steps() {
			let steps = [
				{
					title: 'Book Information',
					component: ProjectInformation
				}
			];

			if(this.themes.length) {
				steps.push({
					title: 'Pick a Theme',
					component: ThemeSelector
				});
			}

			steps.push({
				title: 'Account Information',
				component: PersonalInformation
			});

			if(!this.merchant || this.merchant.upfrontFee > 0) {
				steps.push({
					title: 'Down Payment',
					component: StripeForm
				});
			}

			steps.push({
				title: 'Confirm',
				component: Summary
			});

			return steps;
		}
	},
	methods: {
		nextStep(lastStepEvent) {
			this.currentStep++;

			if(lastStepEvent) {
				this.lastStepEvent = lastStepEvent;
			}
		},
		prevStep() {
			this.currentStep--;
		},
		updateOrder(order) {
			this.order = order;
		}
	},
	mounted() {
		this.loading = true;
		this.errorMessage = null;

		let url = `${this.baseUrl}api/v1/register?` + this.customerQueryAppend;
		axios.get(url).then((response) => {
			this.currency = response.data.currency;
			this.studioOrg = response.data.studioOrg;
			this.details.studioOrgId = this.studioOrg.plicOrgId;
			this.merchant = response.data.merchant;
			this.bookFormats = response.data.bookFormats;
			this.themes = response.data.themes;

			this.$emit('loadRegistration', {
				studioOrg: this.studioOrg
			});

			if(this.merchant.upfrontFee <= 0) {
				this.lastStepEvent = function() {
					return Promise.resolve({
						chargeToken: 'free'
					});
				};
			}

			this.loading = false;
		}).catch((error) => {
			// eslint-disable-next-line
			console.error(error);

			if(error && error.response && error.response.data && error.response.data.reason) {
				this.errorMessage = error.response.data.reason;
			} else if(error && error.message) {
				this.errorMessage = error.message;
			}
			this.loading = false;
			this.displayStep = false;
		});
	}
};
</script>

<style scoped>
	
</style>