<template>
	<div>
		<div class="headline"><v-icon>directions</v-icon> Where can we ship your Book?</div>

		<v-text-field
			v-model="addressLine1"
			label="Address Line 1"
			:error-messages="addressLine1Errors"
			required
			@keyup.enter.native="nextStep"
			type="text"
			ref="mainAddress"/>

		<v-text-field
			v-model="addressLine2"
			label="Address Line 2"
			@keyup.enter.native="nextStep"
			type="text"/>

		<v-text-field
			v-model="city"
			label="City"
			:error-messages="cityErrors"
			required
			@keyup.enter.native="nextStep"
			type="text"/>

		<v-text-field
			v-model="state"
			label="State/Province"
			:error-messages="stateErrors"
			required
			@keyup.enter.native="nextStep"
			type="text"/>

		<v-text-field
			v-model="zip"
			label="ZIP/Postal Code"
			:error-messages="zipErrors"
			required
			@keyup.enter.native="nextStep"
			type="text"/>

		<v-btn
			text
			@click="prevStep">Back</v-btn>
		<v-btn
			color="primary"
			@click="nextStep">Continue</v-btn>
	</div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
	name: 'ShippingInformation',
	props: ['details'],
	data: function() {
		return {
			addressLine1: this.details.shippingInformation.addressLine1,
			addressLine2: this.details.shippingInformation.addressLine2,
			city: this.details.shippingInformation.city,
			state: this.details.shippingInformation.state,
			zip: this.details.shippingInformation.zip
		};
	},
	validations: {
		addressLine1: { required },
		city: { required },
		state: { required },
		zip: { required, minLength: minLength(5), maxLength: maxLength(6) }
	},
	computed: {
		addressLine1Errors() {
			const errors = [];
			if(this.$v.addressLine1.$dirty) {
				this.$v.addressLine1.required || errors.push('Address Line 1 is required');
			}

			return errors;
		},
		cityErrors() {
			const errors = [];
			if(this.$v.city.$dirty) {
				this.$v.city.required || errors.push('City is required');
			}

			return errors;
		},
		stateErrors() {
			const errors = [];
			if(this.$v.state.$dirty) {
				this.$v.state.required || errors.push('State is required');
			}

			return errors;
		},
		zipErrors() {
			const errors = [];
			if(this.$v.zip.$dirty) {
				this.$v.zip.required || errors.push('Zip code is required');
				this.$v.zip.minLength || errors.push('Zip code must be at least 5 numbers');
				this.$v.zip.maxLength || errors.push('Zip code must be less than 6 numbers');
			}

			return errors;
		}
	},
	methods: {
		nextStep: function() {
			this.$v.$touch();
			let error = this.$v.$error;

			if(!error) {
				this.$emit('next-step');
			}
		},
		prevStep: function() {
			this.$emit('prev-step');
		}
	},
	mounted: function() {
		let inputWrapper = this.$refs.mainAddress.$el;
		let input = inputWrapper.querySelector('input');

		if(window.google.maps.places.Autocomplete) {
			this.autocomplete = new window.google.maps.places.Autocomplete(input, {
				types: ['geocode']
			});

			this.autocomplete.addListener('place_changed', () => {
				let place = this.autocomplete.getPlace();

				let streetNumber = place.address_components.filter((component) => {
					return component.types[0] === 'street_number';
				})[0].long_name;
				let streetName = place.address_components.filter((component) => {
					return component.types[0] === 'route';
				})[0].long_name;
				let street = streetNumber + ' ' + streetName;
				if(street) {
					this.addressLine1 = street;
				}

				let city = place.address_components.filter((component) => {
					return component.types[0] === 'locality';
				})[0].long_name;
				if(city) {
					this.city = city;
				}

				let state = place.address_components.filter((component) => {
					return component.types[0] === 'administrative_area_level_1';
				})[0].long_name;
				if(state) {
					this.state = state;
				}

				let zip = place.address_components.filter((component) => {
					return component.types[0] === 'postal_code';
				})[0].long_name;
				if(zip) {
					this.zip = zip;
				}
			});
		}
	}
};
</script>