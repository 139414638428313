<template>
	<v-layout justify-center align-center>
		<div class="white--text">
			<div class="subheading">If this popup does not close in a moment, please close it manually</div>
		</div>
	</v-layout>
</template>

<script>
export default {
	mounted() {
		if(window.BroadcastChannel) {
			let broadcastChannel = new window.BroadcastChannel('instagram-oauth');
			broadcastChannel.postMessage(this.$route.query.accessToken);
			broadcastChannel.close();

			window.open('', '_self').close();
		}
	}	
};
</script>