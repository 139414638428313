<template>
	<v-layout justify-center class="login-page">
		<v-flex xs12 sm8 md4>
			<img class="app-logo" :src="largeLogoUrl">

			<v-card class="elevation-12">
				<!--<v-toolbar color="secondary" dark flat>
					<v-toolbar-title>Sign in with PLIC</v-toolbar-title>
				</v-toolbar>-->

				<v-card-text v-if="!loading">
					<v-form id="loginForm">
						<v-text-field
							label="Username"
							prepend-icon="person"
							type="text"
							v-model="email"
							:error-messages="emailErrors"
							@keyup.enter.native="signIn"
							ref="email"
							id="username"
						></v-text-field>

						<v-text-field
							label="Password"
							prepend-icon="lock"
							type="password"
							v-model="password"
							:error-messages="passwordErrors"
							@keyup.enter.native="signIn"
							@keydown.native="checkCapsLock"
							ref="password"
							id="password"
						></v-text-field>
					</v-form>
				</v-card-text>

				<v-card-actions v-if="!loading">
					<v-btn text @click="resetPassword">Forgot Password?</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="signIn" id="loginButton">Sign In</v-btn>
				</v-card-actions>

				<v-progress-circular class="loading" indeterminate v-if="loading"></v-progress-circular>
			</v-card>

			<div class="policies">
				<a href="/Eula.html" target="_blank" style="margin-right: 1em" class="privacy-policy">License Agreement</a>
				<a href="/PrivacyPolicy.html" target="_blank" class="privacy-policy">Privacy Policy</a>
				<a href="/CookiePolicy.html" target="_blank" style="margin-left: 1em" class="privacy-policy">Cookie Policy</a>
			</div>

			<v-alert
				type="error"
				:value="!!capsLockMessage"
				transition="scale-transition"
				class="bottom-message">

				{{ capsLockMessage }}
			</v-alert>

			<v-alert
				type="error"
				:value="!!errorMessage"
				transition="scale-transition"
				class="bottom-message">

				{{ errorMessage }}
			</v-alert>

			<v-alert
				type="warning"
				:value="!!redirectWarningMessage"
				transition="scale-transition"
				class="bottom-message">

				{{ redirectWarningMessage }}
			</v-alert>

			<v-alert
				type="success"
				:value="!!successMessage"
				transition="scale-transition"
				class="bottom-message">

				{{ successMessage }}
			</v-alert>
		</v-flex>
	</v-layout>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { client, plicAxios, logAxiosError } from '../utils/axios';

// TODO: Implement AU login redirect

export default {
	name: 'LandingPage',
	data() {
		return {
			email: '',
			password: '',
			loading: false,
			errorMessage: null,
			successMessage: null,
			capsLockMessage: null
		};
	},
	validations: {
		email: { required, email },
		password: { required }
	},
	computed: {
		emailErrors() {
			const errors = [];
			if(this.$v.email.$dirty) {
				this.$v.email.required || errors.push('Email is required');
				this.$v.email.email || errors.push('Email is not valid');
			}

			return errors;
		},
		passwordErrors() {
			const errors = [];
			if(this.$v.password.$dirty) {
				this.$v.password.required || errors.push('Password is required');
			}

			return errors;
		},
		redirectWarningMessage() {
			return this.$route?.query?.warningMessage ?? null;
		}
	},
	methods: {
		checkCapsLock(e) {
			// Not actually typing
			if(!e.key) {
				return;
			}

			let isCapsLockOn = e.getModifierState && e.getModifierState('CapsLock');
			if(isCapsLockOn) {
				this.capsLockMessage = 'You are typing your password with the caps lock on!';
			} else {
				this.capsLockMessage = null;
			}
		},
		signIn() {
			this.errorMessage = null;
			this.successMessage = null;
			this.$v.$touch();

			let error = this.$v.$error;
			if(!error) {
				this.loading = true;
				client.post(this.baseUrl + 'ajax/login.php', {
					username: this.email,
					password: this.password,
					rememberMe: true
				}).then((data) => {
					if(this.$route?.query?.redirect) {
						let redirect = this.$route.query.redirect;
						if(redirect[0] === '/') {
							redirect = redirect.substr(1);
						}
						window.location = this.baseUrl + redirect;
					} else if(data.url) {
						window.location = this.baseUrl + data.url;
					} else {
						window.location = this.baseUrl;
					}
				}).catch((error) => {
					this.loading = false;

					this.errorMessage = 'Invalid email or password';
					try {
						if(error.response && error.response.data && error.response.data.reason) {
							let reason = error.response.data.reason;
							if(reason != 'Plic error' && reason != 'Authentication error' && reason != 'Error from PLIC') {
								this.errorMessage = reason;
							}
						}
					} catch(e) {
						// eslint-disable-next-line
						console.error('Failed to login: ', e, error);
					}
				});
			}
		},
		resetPassword() {
			this.errorMessage = null;
			this.successMessage = null;
			this.$v.email.$touch();

			let error = this.$v.email.$error;
			if(!error) {
				this.loading = true;

				plicAxios.post('/users/recover', {
					user: {
						email: this.email
					},
					plic_app_id: process.env.VUE_APP_PLIC_APP_SLUG
				}).then(() => {
					this.loading = false;
					this.successMessage = 'Reset instructions sent. Check your email.';
				}).catch((error) => {
					this.loading = false;
					this.errorMessage = 'Error resetting password';
					logAxiosError(this.errorMessage, error);
				});
			}
		}
	},
	mounted() {
		this.$refs.email.$el.querySelector('input').focus();

		if(this.$route?.query?.username) {
			this.email = this.$route.query.username;
		}
	}
}
</script>

<style scoped>
	.app-logo {
		max-width: 90%;
		margin-top: 2em;
		margin-bottom: 1em;
	}

	.bottom-message {
		margin-top: 1em;
	}

	.loading {
		min-height: 8em;
	}

	.policies {
		margin-top: 1em;
		display: block;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.policies:deep(a) {
		color: white;
	}
</style>