<template>
	<div>
		<div class="headline"><v-icon>email</v-icon> Book Information</div>

		<v-list v-if="project">
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title>Book: {{ project.jobName }}</v-list-item-title>
					<v-list-item-subtitle>Pages: {{ project.pages }}</v-list-item-subtitle>
					<v-list-item-subtitle>Price per copy: {{ prices.perBookPrice | money }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>

		<div>
			<v-text-field
				v-model="details.quantity"
				label="Number of copies"
				:error-messages="copiesErrors"
				required
				@keyup.enter.native="nextStep"
				type="number"/>
		</div>

		<v-divider class="order-summary-divider"></v-divider>

		<v-list>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title>Subtotal: {{ subTotal | money }}</v-list-item-title>
					<v-list-item-title><b>Shipping:</b> {{ prices.flatRateShippingFee | moneyCurrency }}</v-list-item-title>
					<v-list-item-title>Order Total: {{ orderTotal | money }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>

		<v-btn
			color="primary"
			:disabled="dirty"
			@click="nextStep">Next</v-btn>
	</div>
</template>

<script>
import { required, integer, minValue } from 'vuelidate/lib/validators';
import axios from 'axios';
const CancelToken = axios.CancelToken;
let cancelSource = null;

export default {
	props: ['details', 'prices', 'project'],
	validations() {
		return {
			details: {
				quantity: { required, integer, minValue: minValue(this.prices.minimumBookQuantity) }
			}
		};
	},
	data: () => {
		return {
			dirty: false
		};
	},
	computed: {
		copiesErrors() {
			const errors = [];
			if(this.$v.details.quantity.$dirty) {
				this.$v.details.quantity.required || errors.push('Copies is required');
				this.$v.details.quantity.integer || errors.push('Copies is required to be a positive number');
				this.$v.details.quantity.minValue || errors.push(`Required to have at least ${this.prices.minimumBookQuantity} copies`);
			}

			return errors;
		},
		subTotal() {
			return this.prices.perBookPrice * this.details.quantity;
		},
		orderTotal() {
			return this.subTotal + this.prices.flatRateShippingFee;
		}
	},
	watch: {
		'details.quantity'() {
			this.$v.$touch();
			let error = this.$v.$error;
			if(error) {
				return;
			}

			if(cancelSource) {
				cancelSource.cancel();
				cancelSource = null;
			}
			cancelSource = CancelToken.source();

			this.dirty = true;
			axios.get(this.baseUrl + 'api/v1/store?projectId=' + this.$route.params.id + '&quantity=' + this.details.quantity, {
				cancelToken: cancelSource.token
			}).then((response) => {
				this.prices.perBookPrice = response.data.prices.perBookPrice;
				this.dirty = false;
				cancelSource = null;
			}).catch((error) => {
				if(axios.isCancel(error)) {
					return;
				}

				// eslint-disable-next-line
				console.error(error);

				if(error?.response?.data?.reason) {
					this.errorMessage = 'Failed to update price: ' + error.response.data.reason;
				} else if(error?.message) {
					this.errorMessage = error.message;
				}
				cancelSource = null;
			});
		}
	},
	methods: {
		nextStep() {
			this.$v.$touch();

			let error = this.$v.$error;
			if(!error) {
				this.$emit('next-step');
			}
		}
	}
};
</script>

<style scoped>
	.v-list {
		text-align: left;
	}

	.v-btn {
		margin: 0 0.5em;
	}
</style>