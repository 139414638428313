<template>
	<div>
		<div v-if="!loading">
			<div class="headline"><v-icon>email</v-icon> Where can we reach you?</div>

			<v-text-field
				v-model="details.firstName"
				label="First name"
				:error-messages="firstNameErrors"
				required
				@keyup.enter.native="nextStep"
				type="text"/>

			<v-text-field
				v-model="details.lastName"
				label="Last name"
				:error-messages="lastNameErrors"
				required
				@keyup.enter.native="nextStep"
				type="text"/>

			<v-text-field
				v-model="details.email"
				label="Email"
				:error-messages="emailErrors"
				required
				@keyup.enter.native="nextStep"
				type="email"/>

			<v-btn
				text
				@click="prevStep"
				v-if="currentStep > 1">Back</v-btn>
			<v-btn
				color="primary"
				@click="nextStep">Continue</v-btn>

			<v-alert
				type="error"
				:value="!!errorMessage"
				transition="scale-transition"
				style="margin-top: 0.5em">

				{{ errorMessage }}
			</v-alert>
		</div>

		<v-progress-circular indeterminate v-if="loading"></v-progress-circular>
	</div>
</template>

<script>
import axios from 'axios';
import { required, email } from 'vuelidate/lib/validators';

export default {
	props: {
		currentStep: null,
		details: null,
		order: null
	},
	name: 'PersonalInformation',
	validations: {
		details: {
			email: { required, email },
			firstName: { required },
			lastName: { required }
		}
	},
	data() {
		return {
			loading: false,
			errorMessage: null
		};
	},
	computed: {
		emailErrors() {
			const errors = [];
			if(this.$v.details.email.$dirty) {
				this.$v.details.email.required || errors.push('Email is required');
				this.$v.details.email.email || errors.push('Email is not valid');
			}

			return errors;
		},
		firstNameErrors() {
			const errors = [];
			if(this.$v.details.firstName.$dirty) {
				this.$v.details.firstName.required || errors.push('First name is required');
			}

			return errors;
		},
		lastNameErrors() {
			const errors = [];
			if(this.$v.details.lastName.$dirty) {
				this.$v.details.lastName.required || errors.push('Last name is required');
			}

			return errors;
		}
	},
	methods: {
		nextStep() {
			this.$v.$touch();

			let error = this.$v.$error;
			if(!error) {
				this.loading = true;
				axios.post(this.baseUrl + 'api/v1/register', this.details).then((response) => {
					this.details.orderId = response.data.order.orderId;
					this.$emit('update-order', response.data.order);
					this.loading = false;
					this.$emit('next-step');
				}).catch((error) => {
					// eslint-disable-next-line
					console.error(error);

					if(error && error.response && error.response.data) {
						this.errorMessage = error.response.data.reason;
					} else if(error && error.message) {
						this.errorMessage = error.message;
					}

					this.loading = false;
				});
			}
		},
		prevStep() {
			this.$emit('prev-step');
		}
	}
};
</script>

<style scoped>
	.v-btn {
		margin: 0 0.5em;
	}
</style>